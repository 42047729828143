<template>
  <div
    :style="cssVars(card.colors)"
    class="card-level"
  >
    <b-row
      no-gutters
      @click="onCardClick"
    >
      <b-col cols="8">
        <b-row>
          <b-col
            class="label-activity-card"
            cols="12"
          >
            {{ card.name }}
          </b-col>
          <b-col cols="12">
            <label class="label-level-card">
              {{ card.userCurrentLevelIdentifier }} - {{ card.userCurrentLevelLabel }}
            </label>
          </b-col>
        </b-row>
        <b-row
          class="custom-count-row mt-1"
          no-gutters
        >
          <b-col
            align="left"
            cols="7"
          >
            <b-row align="center">
              <b-col cols="12">
                <div class="u-pearl-count-value">
                  <label class="count-value">
                    {{ card.clientBookingsCount }}
                  </label>
                </div>
              </b-col>
              <b-col cols="12">
                <label class="count-label">
                  {{ $t('views.client.details.activities.right-content.bookings') }}
                </label>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="5">
            <b-row align="center">
              <b-col class="ml-0 pl-0" cols="12">
                <div class="u-pearl-count-value">
                  <label class="count-value">
                    {{ card.clientMatchesCount }}
                  </label>
                </div>
              </b-col>
              <b-col class="ml-0 pl-0" cols="12">
                <label class="count-label">
                  {{ $t('views.client.details.activities.right-content.matchs') }}
                </label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="icon-container">
        <d-svg-container
          :icon="card.icon"
          :svg-icon-style="{fill: '#FFFFFF', stroke: 'unset'}"
        />
        <span v-if="selected" class="fa fa-check d-flex justify-content-end"></span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      card: {
        type: Object,
        default: this
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onCardClick() {
        if (!this.selected) {
          this.$emit('on:card:selected', this.card);
        }
      },
      cssVars(colors) {
        return {
          '--firstColor': colors[0],
          '--secondColor': colors[1]
        }
      },
    }
  }
</script>
<style scoped lang="scss">
.custom-count-row .row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -30px;
}

.card-level {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  background: linear-gradient(var(--firstColor), var(--secondColor)) no-repeat;
  opacity: 1;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}

.icon-container {
  opacity: 0.4;
}

.label-activity-card {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: #F1F4F7;
}

.label-level-card {
  font-size: 15px;
  color: #F1F4F7;
}

.count-label {
  font-size: 13px;
  color: #F1F4F7;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.u-pearl-count-value {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 29px;
  height: 30px;
  line-height: 28px;
  color: #4D4F5C;
  font-size: 11px;
  text-align: center;
  background: transparent;
  border: 2px solid #EDF0F3;
  border-radius: 50%;
}

.count-value {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  color: #F1F4F7;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
</style>
